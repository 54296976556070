$off-white: #f5f7f9;
$modal-white: rgb(245, 247, 249);
$grey: #79878f;
$loading-grey: #ebebeb;
$slate: #799eb2;
$light-grey: rgb(225, 228, 229);
// $blue: #286efa;
$blue: #2167f5;
$teal: rgb(101, 172, 212);
$label-grey: #79878F;
$slate: rgb(67, 70, 83);
$black: #343538;
$dark-blue: #153A62;
$primary: #343538;
$white: #FFF;
$border-grey: #DCE6EB;
$green: darken(rgb(112,195,101), 5);
$red: #F26c57;
$purple: darken(#5c6ac4, 10);
$orange: #ffa500;
$yellow: #ffa500;
$slow: .3s;
$fast: .15s;

$mobile-sm: 400px;
$mobile: 600px;
$tablet: 768px;
$laptop: 1000px;
$desktop: 1440px;

$sans: 'proxima-nova', Helvetica, sans-serif;
$display: 'brevia';