@import './reset.scss';
@import './variables.scss';
@import './mixins.scss';

body {
  font-family: $sans;
  background: $off-white;
  margin: 0px;
  color: $black;
  top: 0px;
  padding: 0px;
  position: relative;
}

html, body, #root {
  height: 100%;
}

input {
  font-family: $sans;
  color: $black;
}

*, *:before, *:after {
  box-sizing: border-box;
}

button {
  appearance: none;
  outline: none;
  border: none;
  background: none;
  transition: color $slow;
  font-family: $sans;

  &:hover {
    transition: opacity $fast;
  }
}

strong {
  font-weight: 600;
  color: $black;
}

a {
  color: $black;
  transition: color $slow, background $slow;
  text-decoration: none;
  outline: none;

  &:hover {
    color: $blue;
    transition: color $fast, background $slow;
  }
}

.wrapper {
  @include wrapper;
}

$header-height: 70px;

#zigpoll-container {
  position: absolute !important;
  width: 100% !important;
  top: 0px;
  // top: $header-height * 2 !important;
  height: calc(100%) !important;
  z-index: 5 !important;

  @media(min-width: $mobile) {
    top: 0px !important;
    // top: $header-height !important;
    // height: calc(100% - #{$header-height}) !important;
    left: 0px !important;
    bottom: auto !important;
    left: auto !important;
    z-index: 5 !important;
  }

  #zigpoll-embed {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    &:after, &:before {
      display: none !important;
    }

    #zigpoll-poll-frame {
      max-height: calc(100% - 10px) !important;

      @media(min-width: $mobile) {
        margin: 0px !important;
        position: absolute !important;
        top: 50% !important;
        left: 50% !important;
        // margin-top: -($header-height/2);
        transform: translateY(-50%) translateX(-50%);
        box-shadow: 0 0 0 1px rgba(255,255,255,.15) !important;
      }
    }

    #zigpoll-launcher-frame,
    #zigpoll-status-frame {
      display: none;
    }
  }
}

body {
  overflow: hidden;

  #root, #zigpoll-container, &:after {
    opacity: 0;
    transform: translateZ(0px);
  }

  #root {
    transition: opacity $slow 0s;
  }

  #zigpoll-container {
    transform: translateY(25px) translateZ(0px);
    transition: transform .5s .1s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity .5s cubic-bezier(0.175, 0.885, 0.32, 1.275) .1s !important;
  }

  &.loaded #root, &.loaded #zigpoll-container, &.loaded:after {
    transform: translateZ(0px);
    opacity: 1;
  }

  &.loaded #zigpoll-container {
    transform: translateY(0px) translateZ(0px);
  }
}

#poll {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;

  &.loaded {
    display: block;
  }

  .bg {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    // background: linear-gradient(150deg,darken($blue, 15) 15%, darken($teal, 5) 70%, lighten($teal, 10) 94%);
    // background: linear-gradient(180deg, rgb(16, 44, 102) 12%, rgb(26, 72, 168) 70%, rgb(33, 91, 212));
    // background: linear-gradient(rgb(14, 40, 93), rgb(21, 59, 137));
    background: #0e285d;
    height: 100%;

    @media(min-width: $mobile) {
      height: 40%;
    }
  }

  &.loaded {

  }
}

#header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: $header-height;
  z-index: 3;

  .wrapper {
    height: $header-height;
    position: relative;

    > div {
      line-height: $header-height;
      vertical-align: middle;
    }
  }

  .logo {
    @include logo;

    &:before, a {
      color: $white;
    }
  }
}

#custom-logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;

  img {
    max-height: 80px;

    @media(max-width: $mobile) {
      max-height: 50px;
    }
  }
}

#close-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  // width: 100px;
  font-size: 38px;
  font-weight: 700;
  // padding-top: 25px;
  text-shadow: -1px -1px 0 rgba(255,255,255,1), 1px -1px 0 rgba(255,255,255,1), -1px 1px 0 rgba(255,255,255,1), 1px 1px 0 rgba(255,255,255,1);


  &.wave {
    @media(max-width: $mobile) {
      color: $white !important;
      text-shadow: none !important;
    }
  }

  opacity: 0;
  z-index: 1;

  &.active {
    opacity: 1;
  }
}

#not-found {
  background: $white;
  max-width: 440px;
  width: calc(100% - 10px);
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 10px;
  padding: 30px 10px;
  text-align: center;
  // box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05) !important;
  border: 3px solid rgb(33, 91, 212);

  .top {
    font-weight: 700;
    font-size: 52px;  
    color: darken($off-white, 30);
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .title {
    font-size: 40px;
    margin-bottom: 0px;
    color: $black;
    font-weight: 700;
  }

  .copy {
    font-size: 24px;
    color: $black;
    margin-bottom: 0px;
    margin-top: -5px;
    font-weight: 600;
  }
}

#promo {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
  display: block;
  width: auto;
  text-align: center;
  height: $header-height;
  line-height: $header-height;
  vertical-align: middle;
  left: 50%;
  transform: translateX(-50%);

  @media(max-width: $mobile) {
    top: $header-height;
    width: calc(100% - 20px);
  }

  p {
    line-height: 1;
    vertical-align: middle;
    color: $white;
    display: inline-block;
    background: rgba(255,255,255,.25);
    border-radius: 30px;
    padding: 10px 30px;
    font-weight: 600;
    @media(min-width: $mobile) {
      margin-top: 10px;
    }
    letter-spacing: .25px;
    // box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15) !important;

    a {
      color: $white;
      text-decoration: underline;
    }
  }
}

.wave-divider {
  width: 100%;
  padding-top: 6%;
  height: 0px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: calc(40% + 1px);
  left: 0px;
  width: 100%;
  z-index: 2;
  transform: translateY(-100%);
  display: none;

  @media(min-width: $mobile) {
    display: block;
  }

  &.inverted {
    transform: matrix(-1,0,0,-1.1,0,0) translateZ(0px);
  }

  svg {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
  }

  svg path {
    // fill: $off-white;
    animation: waveDividerAnimation 20s linear infinite alternate;
  }
}

#message {
  position: fixed;
  background: rgba(0,0,0, .85);
  color: $white;
  border-radius: 10px;
  width: calc(100% - 20px);
  z-index: 2;
  padding: 5px 10px;
  bottom: 5px;
  left: 50%;
  display: inline-block;
  text-align: center;
  transform: translateX(-50%);
  display: none !important;

  &.active {
    display: inline-block !important;
  }

  h1, h3, a {
    color: $white;
    font-size: 15px;
    font-weight: 600;
  }
  a {
    text-decoration: none;
  }
}

#language-selector {
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 6;

  select {
    appearance: none;
    outline: none;
    padding: 5px 10px;
    font-size: 12px;
    display: block;
    border-radius: 5px;
    background: transparent;
    border: 1px solid $white;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    transition: color $slow, background $slow;
    cursor: pointer;
  }
}

@keyframes waveDividerAnimation{
  0%{
    d:path("M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z")}
    50%{d:path("M655.558582,28.4898877 C500.191373,61.6062216 422.833785,83.5422763 266.940085,49.8995879 C112.480383,16.5663637 84.0992497,8.02840272 0,44.7243294 L0,150.727527 L1920,150.727527 L1920,49.8995879 C1580.91676,-34.8808285 1438.10804,73.6768193 1148.0266,38.0019829 C857.945166,2.32714659 810.925791,-4.62644617 655.558582,28.4898877 Z")}
    to{d:path("M842.322034,38.0019829 C686.954825,71.1183168 623.386282,53.08497 467.492582,19.4422816 C313.032879,-13.8909426 84.0992497,8.02840272 0,44.7243294 L0,150.727527 L1920,150.727527 L1920,76.8685643 C1512.23604,-35.3595639 1568.70222,67.4289432 1278.62078,31.7541069 C988.539347,-3.92072949 997.689243,4.88564905 842.322034,38.0019829 Z")}}


#loading {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  @include loading-alt;
  transition: opacity $fast;
  z-index: 0;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}

body.loaded #loading {
  opacity: 0 !important;
}

body.loaded.closed {
  #zigpoll-container {
    pointer-events: none;
  }
}